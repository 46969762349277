let columnsRowsItemsElements = document.querySelectorAll(
  ".columns-and-rows-items",
);

for (let i = 0; i < columnsRowsItemsElements.length; i++) {
  let columns = columnsRowsItemsElements[i].querySelectorAll(
    ".columns-and-rows-item",
  );
  if (columns.length % 2 == 0) {
    columns[columns.length - 2].classList.add("remove--border");
  }

  if (columns.length == 2) {
    columns[0].classList.add("remove--border");
  }
}
